<template>
    <div class="members-head">
        <div class="header">
            <div class="image">
                <el-image class="img1" :src="img1Url" fit="fill"></el-image>
                <el-image class="img2" :src="img2Url" fit="fill"></el-image>
                <div class="text">会员信息</div>
            </div>
            <div class="info-list">
                <div class="info" style="width: 50px">
                    <div class="label">姓名:</div>
                    <div class="name">{{user.userName || '&nbsp;'}}</div>
                </div>
                <div class="info" style="width:28px">
                    <div class="label">性别:</div>
                    <div class="name">{{user.sex ? '女' : '男'}}</div>
                </div>
                <div class="info" style="width: 100px">
                    <div class="label">公司名称:</div>
                    <div class="name ellipsis">{{user.corpName || '&nbsp;'}}</div>
                </div>
                <div class="info" style="width: 80px">
                    <div class="label">部门:</div>
                    <div class="name">{{user.department || '&nbsp;'}}</div>
                </div>
                <div class="info" style="width: 80px">
                    <div class="label">岗位:</div>
                    <div class="name">{{user.post || '&nbsp;'}}</div>
                </div>
                <div class="btn">
                    <el-button round icon="el-icon-edit" @click="changeInfo">修改信息</el-button>
                    <el-button round icon="el-icon-edit" @click="changePassword" class="bottom">修改密码</el-button>
                </div>
                <div class="info">
                    <div class="label">会员状态:</div>
                    <div class="name">{{user.roleName || '&nbsp;'}}</div>
                </div>
                <div class="info">
                    <div class="label">会员权限时间:</div>
                    <div class="name" v-if="user.roleId === this.$variable.member.temp">&nbsp;</div>
                    <div class="name" v-else-if="user.roleId === this.$variable.member.visit">&nbsp;</div>
                    <div class="name" v-else>{{user.tempDateEnd || '永久'}}</div>
                </div>
            </div>
            <div class="pay">
                <div class="pay-record" @click="showPay">
                    <span>支付记录</span>
                    <i class="el-icon-arrow-right"></i>
                </div>
                <div class="pay-card" @click="getOpenBank">
                    <span>银行账户信息</span>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>

            <div class="integral-upload">
                <div class="integral">
                    <el-image class="img3" :src="img3Url" fit="fill"></el-image>
                    <div class="integral-content">
                        <div class="text">积分</div>
                    <div class="num">{{user.userIntegral}}</div>
                    <div class="btn" @click="integralRules.show=true">
                        <el-button round>规则</el-button>
                    </div>
                    </div>
                </div>
                <div class="upload">
                    <div class="btn record">
                        <el-button round icon="el-icon-edit-outline" @click="$router.push('/upload')">上传文献</el-button>
                    </div>
                    <div class="record">
                        <span  @click="$router.push('/upload-record')">上传记录</span>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                    <div class="record" @click="showDetail">
                        <span>积分明细</span>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- 修改信息 -->
        <mem-dialog :dialog-show="info">
            <div class="title" slot="title" >
                <i class="el-icon-tickets"></i>
                <span>修改信息</span>
            </div>
            <el-form slot="content" class="changeInfo">
                <el-form-item >
                    <div class="input">
                        <div class="label">姓名</div>
                        <el-input v-model="info.user.userName" clearable placeholder="请输入姓名"></el-input>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div class="input">
                        <div class="label">性别</div>
                        <el-radio-group v-model="info.user.sex">
                            <el-radio :label="0">男</el-radio>
                            <el-radio :label="1">女</el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div class="input">
                        <div class="label">公司名称</div>
                        <el-input v-model="info.user.corpName" clearable placeholder="请输入公司名称"></el-input>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div class="input">
                        <div class="label">部门</div>
                        <el-input v-model="info.user.department" clearable placeholder="请输入部门"></el-input>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div class="input">
                        <div class="label">岗位</div>
                        <el-input v-model="info.user.post" clearable placeholder="请输入部门"></el-input>
                    </div>
                </el-form-item>
            </el-form>
            <el-button type="primary" slot="footer" @click="onSubmit" :loading="info.loading">确 定</el-button>
        </mem-dialog>

        <!-- 修改密码弹框 -->
        <mem-dialog :dialog-show="password">
            <div class="title" slot="title" >
                <i class="el-icon-lock"></i>
                <span>修改密码</span>
            </div>
            <div slot="content">
                <el-form :model="password.form" status-icon :rules="password.rules" ref="ruleForm" class="ruleForm">
                    <el-form-item prop="oldPassword">
                        <el-input type="password" placeholder="请输入原密码" v-model="password.form.oldPassword" autocomplete="off" show-password></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input type="password" placeholder="请输入新密码" v-model="password.form.password" autocomplete="off" show-password></el-input>
                    </el-form-item>
                    <el-form-item prop="confirmPassword">
                        <el-input type="password" placeholder="请再次输入新密码" v-model="password.form.confirmPassword"
                                  autocomplete="off" show-password></el-input>
                    </el-form-item>
                </el-form>
                <div  class="tips">注：密码由6-8位数字、大小写字母组成</div>
            </div>
            <el-button type="primary" slot="footer" @click="submitForm" :loading="password.loading">确 定</el-button>
        </mem-dialog>

        <!-- 支付记录 -->
        <mem-dialog :dialog-show="pay" class="pay-dialog">
            <div class="title" slot="title" >
                <i class="el-icon-s-finance"></i>
                <span>支付记录</span>
            </div>
            <div slot="content">
                <div style="height: 300px; overflow-y: auto; padding-left: 150px">
                    <el-steps direction="vertical" >
                        <el-step title="开通试用会员" v-for="item in pay.list" :key="item.id">
                            <div slot="icon">
                                <el-image class="step" :src="stepUrl" fit="fill"></el-image>
                            </div>
                            <div slot="description" class="description">
                                <div class="text">
                                    <span class="label">支付费用:</span>
                                    <span class="value">{{item.price}}元</span>
                                </div>
                                <div class="text">
                                    <span class="label">支付方式:</span>
                                    <span class="value">{{item.payType === 'wechat' ? '微信支付' : '支付宝'}}</span>
                                </div>
                                <div class="text">
                                    <span class="label">权限时间:</span>
                                    <span class="value">{{item.timeEnd}}</span>
                                </div>
                                <div class="date">
                                    <div class="year">
                                        {{item.createdAt ? item.createdAt.split('-')[0] : ''}}年
                                    </div>
                                    <div class="month">
                                        <span class="color">{{item.createdAt ? item.createdAt.split('-')[1] : ''}}月{{item.createdAt ? item.createdAt.split(' ')[0].split('-')[2] : ''}}日</span>
                                        <span>{{item.createdAt ? item.createdAt.split(' ')[1] : ''}}</span>
                                    </div>
                                </div>
                            </div>
                        </el-step>
                    </el-steps>
                </div>
            </div>
        </mem-dialog>

        <!-- 银行账户信息 -->
        <mem-dialog :dialog-show="bank" class="bank-dialog">
            <div class="title" slot="title" >
                <i class="el-icon-bank-card"></i>
                <span>银行账户信息</span>
            </div>
            <div slot="content">
                <div class="bank">
                    <span>卡号:</span>
                    <span class="color">{{bank.openBankAccount}}</span>
                </div>
                <div class="bank">
                    <span>开户名:</span>
                    <span class="color">{{bank.openBankUserName}}</span>
                </div>
                <div class="bank">
                    <span>开户行:</span>
                    <span class="color">{{bank.openBankName}}</span>
                </div>
            </div>
        </mem-dialog>

        <!-- 积分规则 -->
        <mem-dialog :dialog-show="integralRules" class="bank-dialog">
            <div class="title" slot="title" >
                <i class="el-icon-tickets"></i>
                <span>积分规则</span>
            </div>
            <div slot="content">
                <div class="content">
                    <p>
                        1.上传1篇文章获得3个积分;
                    </p>
                    <p>
                        2.反馈网站问题可获得1个积分;
                       
                    </p>
                    <p>
                        3.阅读可以获得1个积分;
                    </p>
                    <p>
                        4.购买试用会员可以获得198个积分，购买VIP可获得50000个积分。
                    </p>
                </div>
            </div>
        </mem-dialog>

        <!-- 积分明细 -->
        <mem-dialog :dialog-show="detail" class="details-dialog">
            <div class="title" slot="title" >
                <i class="el-icon-coin"></i>
                <span>积分明细</span>
            </div>
            <div slot="content">
                <div class="input-list">
                    <el-select v-model="detail.selectYear" size="medium" filterable @change="changeYear">
                        <el-option v-for="item in detail.yearLength" :key="item + 'year'" :label="(detail.year + item - 1)+'年'"
                                   :value="detail.year + item - 1"></el-option>
                    </el-select>
                    <el-select v-model="detail.selectMonth" size="medium" @change="changeMonth" style="margin-left: 25px;">
                        <el-option v-for="(item, i) in 12" :key="i" :label="item+'月份'" :value="item"></el-option>
                    </el-select>
                </div>
                <div class="tip">
                    <div>
                        全年度共获得 <span>{{detail.yearTotal}}</span> 积分 ，{{detail.selectMonth}}月获得 <span>{{detail.monthTotal}}</span> 积分
                    </div>
                </div>
                <div style="height: 300px; overflow-y: scroll; padding-left: 120px">
                    <el-steps direction="vertical" >
                        <el-step v-for="(item, i) in detail.list" :key="i + 'scoreDetail'" class="last">
                            <div slot="title" class="title">
                                <!--<el-collapse v-model="item.active" @change="changeActive">-->
                                <el-collapse v-model="item.active">
                                    <el-collapse-item >
                                        <div slot="title">
                                            获取 <span>{{item.dayInteger}}</span> 积分
                                        </div>
                                        <div class="description" v-for="(child, j) in item.list" :key="child.id" :name="j + 'adsad'">
                                            <div class="text">
                                                <div class="time">
                                                    <span>{{child.createdAt.Format('hh:mm:ss')}}</span>
                                                </div>
                                                <div class="dian">·</div>
                                                <span class="label">{{child.reason}}</span>
                                                <span class="value">+{{child.integral}}</span>
                                            </div>
                                        </div>
                                    </el-collapse-item>
                                    <div class="date">
                                        <div class="month">
                                            <span class="color">{{item.day}}</span>
                                        </div>
                                    </div>
                                </el-collapse>
                            </div>
                            <div slot="icon">
                                <el-image class="step" :src="stepUrl" fit="fill"></el-image>
                            </div>
                        </el-step>
                    </el-steps>
                </div>
            </div>
        </mem-dialog>

        <el-result class="mem-reslute" title="修改成功" v-show="cpTure">
            <template slot="icon">
                <el-image class="step" :src="imgSuccess" fit="fill"></el-image>
            </template>
        </el-result>

        <el-result class="mem-reslute" title="修改失败" v-show="cpFalse">
            <template slot="icon">
                <el-image class="step" :src="imgFail" fit="fill"></el-image>
            </template>
        </el-result>


    </div>
</template>

<script>
import MemDialog from './MemDialog.vue'
export default {
    components: { MemDialog },
    data() {
        let validatePass = (rule, value, callback) => {
            if (!(/^[0-9A-Za-z]{6,8}$/.test(value))) {
                callback(new Error('密码由6-8位数字、大小写字母组成'));
            } else {
                callback();
            }
        };
        let validatePassAgain = (rule, value, callback) => {
            if (this.password.form.password !== value) {
                callback(new Error('两次密码不一致,请重新填写'));
            } else {
                callback();
            }
        };
        return {
            img1Url: require('@/assets/img/member/img1.png'),
            img2Url: require('@/assets/img/member/img2.png'),
            img3Url: require('@/assets/img/member/img3.png'),
            stepUrl: require('@/assets/img/member/step.png'),

            // 用户信息
            user: {},


            imgSuccess: require('@/assets/img/member/changeSuccess.png'),
            imgFail: require('@/assets/img/member/changeFail.png'),
            cpTure: false,
            cpFalse: false,
            info: {
                show: false,
                loading: false,
                user: {},
            },
            password: {
                show: false,
                loading: false,
                form: {
                    oldPassword: '',
                    password: '',
                    confirmPassword: ''
                },

                rules: {
                    oldPassword: [
                        { required: true, message: '请输入原密码', trigger: ['blur', 'change'] }
                    ],
                    password: [
                        { required: true, message: '请输入新密码', trigger: ['blur', 'change'] },
                        { validator: validatePass, trigger: ['blur', 'change'] }
                    ],
                    confirmPassword: [
                        { required: true, message: '请再次输入新密码', trigger: ['blur', 'change'] },
                        { validator: validatePassAgain, trigger: ['blur', 'change'] }
                    ],
                },
            },
            pay: {
                show: false,
                list: []
            },
            integralRules: {
                show: false
            },
            bank:{
                show: false,
                openBankName: '',
                openBankAccount: '',
                openBankUserName: '',
            },
            detail:{
                show: false,
                selectMonth: new Date().getMonth()+1,
                selectYear: new Date().getFullYear(),
                year: 2018,
                yearLength: new Date().getFullYear() - 2018  + 1,
                list: [],

                yearTotal: '',
                monthTotal: '',
            },
            ruleForm: {
                oldPassword: '',
                password: '',
                confirmPassword: ''
            },
            rules: {
                oldPassword: [
                    { required: true, message: '请输入原密码', trigger: ['blur', 'change'] },
                    { validator: validatePass, trigger: ['blur', 'change'] }
                ],
                password: [
                    { required: true, message: '请输入新密码', trigger: ['blur', 'change'] },
                    { validator: validatePass, trigger: ['blur', 'change'] }
                ],
                confirmPassword: [
                    { required: true, message: '请再次输入新密码', trigger: ['blur', 'change'] },
                    { validator: validatePassAgain, trigger: ['blur', 'change'] }
                ],
            },
            showLast: false,
        }
    },
    methods: {
        changeTip() {
            setTimeout(()=>{
                this.cpTure = false
                this.cpFalse = false
            },2000)
        },
        // region 用户信息操作
        getUserInfo(flag) {
            this.$store.dispatch('getUser', flag)
                .then(res => {
                    if (res.id) {
                        // 对比会员时间
                        if (res.roleId === this.$variable.member.vip && res.tempDateEnd) {
                            const now = new Date().getTime()
                            const memerTime = new Date(res.tempDateEnd).getTime()

                            if (now > memerTime) {
                                res.roleId = this.$variable.member.visit
                                res.roleName = '游客'
                            }
                        }

                        this.user = res
                    } else {
                        this.$help.goLogin()
                    }
                })
                .catch(() => {
                    this.$help.goLogin()
                })
        },

        //获取银行信息
        getOpenBank() {
            this.bank.show=true
            this.$api.get('setting/bankInfo/bank').then(res=>{
                // 银行信息
                this.bank.openBankName = res.data.openBankName
                this.bank.openBankAccount = res.data.openBankAccount
                this.bank.openBankUserName = res.data.openBankUserName
            })
        },
        changeInfo() {
            this.info.show = true
            this.info.loading = false
            this.info.user = this.$help.deepClone(this.user)
        },
        onSubmit() {
            if (!this.info.user.userName) {
                this.$store.commit('error', '请输入姓名')
                return
            }
            this.info.loading = true
            this.$api.post('sys/sysUser/changeMeInfo', {
                userName: this.info.user.userName,
                sex: this.info.user.sex,
                department: this.info.user.department,
                corpName: this.info.user.corpName,
            })
                .then(() => {
                    this.info.show = false
                    this.cpTure = true
                    this.getUserInfo(true)
                    //this.$store.commit('success', res.message)
                })
                .catch(()=>{
                    this.cpFalse = false
                })
                .finally(() => {
                    this.info.loading = false
                    this.changeTip()
                })
        },


        changePassword() {
            this.password.show = true
            this.password.form.password = ''
            this.password.form.oldPassword = ''
            this.password.form.confirmPassword = ''
        },
        //修改密码提交
        submitForm() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.password.loading = true
                    this.$api.post('sys/sysUser/changeMePassword', this.password.form)
                        .then(() => {
                            this.password.show = false
                            this.cpTure = true
                        })
                        .catch(()=>{
                            this.cpFalse = true
                        })
                        .finally(() => {
                            this.password.loading = false
                            this.changeTip()
                        })
                } else {
                    return false;
                }
            })
        },
        // endregion

        // 显示支付记录
        showPay() {
            this.pay.show = true
            this.$api.post('sys/sysUserPayment/querySysUserPaymentPage', {params: {status: 3}, page: {pageNo: 1, pageSize: 1000}})
                .then(res => {
                    if(res.data.records && res.data.records.length !== 0){
                        this.pay.list = res.data.records
                    }
                })
        },

        // 显示积分明细
        showDetail() {
            this.detail.show = true
            this.$api.get('sys/sysUserIntegralRecord/querySysUserIntegralRecordList', {
                year: this.detail.selectYear,
                month: this.detail.selectMonth,
            })
                .then(res => {
                    this.detail.list = res.data.dayItem
                    this.detail.yearTotal = parseInt(res.data.yearInteger) 
                    this.detail.monthTotal = parseInt(res.data.monthInteger)
                })
        },

        //通过年份查看积分明细
        changeYear() {
            this.showDetail()
        },
        changeMonth() {
            this.showDetail()
        },
        // changeActive() {
        //     this.showLast = this.activeName.includes(5)
        // }
    },
    mounted() {
        this.getUserInfo()
    },
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    $gray: #D5D6D8;
    $pink: #F82828;
    .members-head{
        box-shadow: 5px 5px 6px #1C1E23;
        .header{
            width: 1440px;
            height: 130px;
            background-color: #242933;
            margin: 0 auto;
            display: flex;
            align-items: center;
            overflow: hidden;
        }
        .image{
            position: relative;
            height: 130px;
            width: 130px;
            .img1{
                height: 130px;
                width: 130px;
            }
            .img2{
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .text{
                position: absolute;
                top: 55%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 18px;
                width: 100%;
                text-align: center;
            }
        }
        .info-list{
            display: flex;
            flex: 1;
            align-items: flex-start;
            //padding: 0 10px;
            .info{
                flex-shrink: 0;
                padding-left: 25px;
                .label{
                    color: $gray;
                }
                .label, .name{
                    line-height: 33px;
                }
                .ellipsis{
                    max-width: 100px;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
                .name{
                    font-size: 15px;
                    font-weight: bold;
                    word-break: break-all;
                    line-height: 1;
                }
            }
            .btn{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
                .bottom{
                    margin-top: 10px;
                }
                .el-button{
                    margin-left: unset;
                    color: $gray;
                    background-color: transparent;
                    border: 1px solid $gray;
                }
            }
        }
        .pay{
            padding: 0 20px;
            &-record, &-card{
                color: $gray;
                line-height: 33px;
                width: 100px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
        .integral-upload{
            display: flex;
            .upload{
                height: 130px;
                width: 170px;
                background-color: #72443F;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .btn{
                    .el-button{
                        color: #72443F;
                        border: 1px solid #72443F;
                    }
                }
                .record{
                    padding: 5px 0;
                    color: $gray;
                    .el-icon-arrow-right{
                        padding-left: 15px;
                    }
                }
            }

        }
        .integral{
            height: 130px;
            width: 130px;
            position: relative;
            .img3{
                height: 130px;
                width: 130px;
            }
            &-content{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                align-items: center;
                flex-direction: column;
                .text{
                    font-size: 18px;
                }
                .num{
                    font-size: 30px;
                    font-weight: bold;
                    max-width: 120px;
                    padding: 0 5px 5px 5px;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
                .btn{
                    .el-button{
                        background-color: transparent;
                        border: 1px solid #fff;
                        color: #fff;
                        padding: 5px 12px;
                        font-size: 12px;
                    }
                }

            }

        }
        .pay-dialog{
            .el-dialog__body{
                padding: 20px 0 0 0;
                .el-steps{
                    &:last-child{
                         .el-step__line{
                            display: block;
                            .el-step__line-inner{
                                transition-delay: -600ms; 
                                border-width: 0px; 
                                height: 0%;
                              
                            }   
                        }
                    }
                }
                .el-step{
                    position: relative;
                    &__head{
                        color: #F89844;
                    }
                    &__icon.is-text{
                        border: 0;
                        .step{
                            margin-top: 10px;
                        }
                    }
                    &__line{
                        background-color: #F89844;
                    }
                    &__title{
                        color: #F89844;
                    }
                    .description{
                        padding: 10px 0 20px 0;
                        .text{
                            .label{
                                padding-right: 10px;
                                color: #666;
                            }
                            .value{
                                color:#000000;
                            }
                        }
                    }
                    .date{
                        position: absolute;
                        top: 0;
                        left: -130px;
                        display: flex;

                        .year{
                            font-size: 18px;
                            color: #000000;
                            font-weight: bold;
                            font-style: italic;
                        }
                        .month{
                            display: flex;
                            flex-direction: column;
                            margin-left: 10px;
                            .color{
                                color: #000033;
                            }
                        }
                    }
                }
            }
        }
        .bank-dialog{
            .el-dialog__body{
                padding: 10px 20px 0 20px;
            }
        }
        .details-dialog{
            .el-dialog__body{
                padding: 10px 0 0 0;
                .input-list{
                    display: flex;
                    padding: 0 15px 15px 15px;
                    .el-select {
                        flex: 1;
                        border: 1px solid #F9D4B3;
                        border-radius: 4px;
                        input {
                            border: 0;
                            box-shadow: #F9D4B377 0 0 10px inset;
                        }
                    }
                    .el-input__suffix {
                        padding: 0 8px;
                        border-left: 1px solid #F9D4B3;
                    }
                }
                .tip{
                    padding: 0 25px 15px 25px;
                    span{
                        color: $pink;
                        font-weight: bold;
                    }
                }
                .el-steps--vertical {
                    height: unset;
                }
                .el-collapse{
                    border: 0;
                    &-item__wrap{
                        border: 0;
                        overflow: unset;
                    }
                    &-item__header{
                        line-height: unset;
                        height: unset;
                        font-size: 16px;
                        font-weight: bold;
                        border: 0;
                        span{
                            color: $pink;
                        }
                    }
                    &-item__content{
                        padding-bottom: 3px;
                    }
                }
                .el-steps{
                    .last{
                        .el-step__line{
                            display: block;
                            .el-step__line-inner{
                                transition-delay: -600ms; 
                                border-width: 0px; 
                                height: 0%;
                              
                            }   
                        }
                    }
                }
                .el-step{
                    position: relative;
                    &__head{
                        color: #F89844;
                    }
                    &__icon.is-text{
                        border: 0;
                        .step{
                            margin-top: 10px;
                        }
                    }
                    &__line{
                        background-color: #F89844;
                    }
                    &__title{
                        color: #F89844;
                    }
                    .description{
                        padding: 10px 20px 0 0;
                        .text{
                            display: flex;
                            justify-content: space-between;
                            line-height: 30px;
                            position: relative;
                            .time{
                                position: absolute;
                                top: 0;
                                left: -110px;
                            }
                            .dian{
                                position: absolute;
                                top: 0;
                                left: -26px;
                                font-size: 21px;
                                font-weight: bold;
                                color: #F89844;
                            }
                            .label{
                                padding-right: 10px;
                                color: #666;
                            }
                            .value{
                                color: $pink;
                            }
                        }
                    }
                    .date{
                        position: absolute;
                        top: 0;
                        left: -100px;
                        display: flex;
                        .month{
                            display: flex;
                            flex-direction: column;
                            margin-left: 10px;
                            .color{
                                font-size: 18px;
                                font-weight: bold;
                                color: #000000;
                            }
                        }
                    }
                }
            }
        }
        .mem-reslute{
            background-color: #fff;
            position: fixed;
            top: 40%;
            left: 50%;
            width: 160px;
            height: 160px;
            z-index: 888;
            transform: translateX(-50%);
            border-radius: 10px;
            /*box-shadow: 0 0 9px 6px #CACACA;*/
            box-shadow: 0 0 7px 1px #cacacaaa;
            .el-image{
                padding-right: 10px;
            }
            .el-result__title{
                margin-top: 5px;
                p{
                    color: #333333;
                }
            }
        }
    }

</style>