<template>
    <div class="mem-dialog">
        <el-dialog center :visible.sync="dialogShow.show" :width="defaultWidth" top="25vh" :close-on-press-escape="false" :close-on-click-modal="false">
            <slot name="content"></slot>
            <div slot="title">
                <el-image class="img1" :src="img1Url" fit="fill"></el-image>
                <div class="head">
                    <slot name="title"></slot>
                    <div class="close" @click="dialogShow.show = false">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <slot name="footer"></slot>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dialogShow: {
            type: Object,
            default: () =>{
                return {
                    show: false,
                }
            }
        },
        defaultWidth: {
            type: String,
            default: '453px'
        }
    },
    data() {
        return {
            img1Url: require('@/assets/img/member/dialog-top.png'),
        }
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .mem-dialog{
        .el-dialog{
            border-radius: 10px;
            &__header{
                padding: unset;
                position: relative;
                .head{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-right: 20px;
                    color: #fff;
                    .title{
                        line-height: 54px;
                        font-size: 16px;
                        font-weight: bold;
                        padding-left: 20px;
                        display: unset;
                        i{
                            padding-right: 10px;
                        }
                    }
                    .close{
                        padding: 5px;
                        .el-icon-close{
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }
                    
                }
            }
            &__headerbtn{
                display: none;
            }
            &__body{
                padding: 30px 67px;
                .ruleForm{
                    .el-form-item__error{
                        background-color: red;
                        color: #fff;
                        width: calc(100% - 10px);
                        line-height: 20px;
                        z-index: 222;
                        padding-left: 10px;
                    }
                    .is-success{
                        input{
                            border: 1px solid green;
                        }
                        .el-input__suffix{
                            background-image: url('~@/assets/img/member/success.png');
                            background-position: center;
                            background-repeat: no-repeat;
                            //background-attachment:fixed;
                            i{
                                opacity: 0;
                            }
                        }
                    }
                    .is-error{
                        .el-input__suffix{
                            background-image: url('~@/assets/img/member/error.png');
                            background-position: center;
                            background-repeat: no-repeat;
                            //background-attachment:fixed;
                            i{
                                opacity: 0;
                            }
                        }
                    }
                }
                .bank{
                    padding: 10px 0;
                    border-bottom: 1px solid #ededed;
                    display: flex;
                    justify-content: space-between;
                    .color{
                        color: #000000;
                    }
                }
            }
            &__footer{
                .dialog-footer{
                    .el-button{
                        padding: 16px 54px;
                        background-image: linear-gradient(to right, #F67C46 , #F9A245);
                        border: 0;
                    }
                }
            }
        }
        
        .el-form-item__content{
            .input{
                display: flex;
                align-items: center;
                border: 1px solid #DCDFE6; 
                line-height: 44px;
                border-radius: 5px;
                .label{
                    border-radius: 5px 0 0 5px;
                    width: 120px;
                    text-align: center;
                    background-color: #FAF8F6;
                    border-right: 1px solid #DCDFE6;
                }
                .el-input{
                    width: 180px;
                }
                .el-radio-group{
                    margin-left: 30px;
                    .el-radio__input.is-checked .el-radio__inner{
                        border-color: #767676 ;
                        background: #767676 ;
                    }
                    .el-radio__label{
                        color: #666;
                    }
                }
                input{
                    border: 0;
                }
            }
            .info-input{
                input:focus{
                    border-right: 1px solid #DCDFE6;
                }
            }
            .el-input{
                input{
                    height: 44px;
                    padding-left: 30px;
                }
                &-group__prepend{
                    width: 79px;
                    text-align: center;
                    background-color: #FAF8F6;
                    color: black;
                }
            }
        }
        .tips{
            color: #999999;
            font-size: 10px;
        }
        
    }
</style>